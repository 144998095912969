form {

    input {
        font-size: 12px !important;
        color: #3E4562 !important;
    }

    .header-input {
        width: 120px !important;
        border: 1px solid #C4C4C4;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        height: 35px;



        .MuiInput-underline::before {
            border-bottom: none !important;



            &:hover {
                border-bottom: none !important;
            }
        }

    }
}

.header-active-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.6px;
    margin-left: 3rem;

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 3;

        h5 {
            margin: 8px 0 0 0;
        }

        .team-inbox-header {
            margin-top: 2rem;
            font-size: 1.5rem;

            &::after {
                content: "Beta";
                position: relative;
                font-size: 14px;
                font-weight: normal;
                bottom: 16px;
            }
        }
    }

    > h5 {
        margin: 8px 0 0 0;
        font-size: 1.5rem !important;
    }

    > .team-inbox-header {
        &::after {
            content: "Beta";
            position: relative;
            font-size: 14px;
            font-weight: normal;
            bottom: 16px;
        }
    }

    .section_title {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid #EBEEF1;
        border-bottom: none;
        border-radius: 10px 10px 0 0;
        background-color: white;
        padding: 1px 1px 0 1px;
        width: fit-content;

        * {
            text-decoration: none;
        }
    }

    h5 {
        margin: 0;
        margin-right: 4rem;
        color: #41416e;
        font-size: 1rem;
        padding-left: 1rem;
    }

    h6 {
        margin: 0;
        width: 10rem;
        text-align: center;
        // margin-right: 1.5rem;
        // margin-left: 0.7rem;
        color: #6E757C;
        font-weight: 500;
        position: relative;
        padding: 8px 0px;
        border-radius: 10px 10px 0 0;
    }

    h6.activeLink {
        color: #5956E9;
        background-color: #f4f4f4;
        font-weight: 600;
    }

    // h6.activeLink::after {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 1.5px;
    //     bottom: -5px;
    //     left: 0;
    //     background-color: #5956E9;
    // }

    h6:hover {
        color: #5956E9;
    }

    span {
        color: #999FAE;
        font-weight: 400;
        font-size: 14px;

    }

}

#page-topbar {

    .dashboard-filter {
        margin: 1rem;
        display: flex;
        align-items: flex-end;
        justify-content: end;
    }
}