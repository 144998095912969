.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  text-align: center;
  h3 {
    font-weight: 500;
    color: black;
    font-size: 24px;
  }
  h5 {
    color: #737680;
  }
  .buttons {
    button {
      background-color: black;
      color: white;
      margin-top: 2rem;
      padding: 0.5rem 2rem;
      border-radius: 5px;
    }
    .secondBtn {
      background-color: white;
      color: black;
      border: 1px solid black;
      margin-left: 1rem;
    }
  }
}
