.webhook-integration-header {
  align-items: center;
  
  justify-content: space-between;


 .left {
  gap: 1rem;
  align-items: center;
  .webhook-icon {
    height: 2rem;
  }
  div {
    h5{
      font-weight: 600;
      font-size: 18px;
      color: black;
    }
    p{
      font-weight: 500;
      font-size: 14px;
      color: #737680;
    }
  }
 }
 .toggle-btn {
  background: #5856E8!important;
  padding: 4px 1rem;
  border-radius: 20px;
  border: none!important;
  height: fit-content;
font-size: 14px;
 }
 .toggle-btn.active{

 }
 .toggle-btn.inactive{

 }
}
