.toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;

    button {
        width: 4rem;
        height: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: black;
        border: 1px solid #cecece;
        border-radius: 4px;
        box-shadow: 1px 2px #cecece;
      
    }

    button:hover {
            color: #fff !important;
            background: #008FFB !important;
        
    }
}

.active {
    color: #FFFFFF !important;
    background: #008FFB !important;
    border: 1px solid blue !important;
    border-bottom: 2px solid blue !important;
}

