.loginPageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('./purple-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container {
  color: white;
  background: #47345dba;
  width: 32rem;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-bottom: 2rem;
}

.login-btn {
  margin: 2rem 0;
  width: 100%;
  background-color: white;
  color: black;
  padding: 0.5rem 0;
  border-radius: 50px;
}