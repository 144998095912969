.salesforce-not-connected-con {
  border: 1px solid #e2e8f0;
  padding: 2rem;
  width: 80vw;
  //   height: 80vh;
  margin-top: 2rem;
  border-radius: 10px;
  height: fit-content;

  .header-con {
    align-items: center;
    .hubspot-icon-big {
      height: 5rem;
      border-radius: 10px;
    }

    .connection-status {
      margin: 0 4rem;
      .name-con {
        align-items: center;
        h3 {
          margin-right: 2rem;
        }
      }
    }

    .connect-salesforce-btn {
      color: white;
      background-color: #7579ff;
      border-radius: 20px;
      padding: 4px 16px;
      height: fit-content;
      margin: 0 auto;
    }

    .unlink-salesforce-btn {
      color: white;
      background-color: #cb1111;
      border-radius: 20px;
      padding: 4px 16px;
      height: fit-content;
      margin: 0 auto;
      margin-left: 1.5rem;
    }
  }

  .info-con {
    margin-top: 2rem;
    padding-top: 1rem;
    padding-right: 40px;
    border-right: 2px solid black;
    h3 {
    }
    .features {
      margin-left: 1rem;
      margin-top: 1rem;
      .feature-item {
        padding-bottom: 1rem;
        align-items: center;
        padding-top: 16px;
        border-bottom: 1px solid #e2e8f0;
        width: 18rem;
        .feature-img {
          height: 2rem;
          margin-right: 1rem;
          border-radius: 10px;
        }
        .feature-text {
          font-weight: 500;
        }
      }
    }
  }
  .salesforce-features-section {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }
  .salesforce-features-right{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
    margin-top: 50px;
    margin-left: 40px;
  }
}
