.webhook-integration-section {
    * {
    font-family: 'Montserrat', sans-serif !important;

    }
    gap: 2rem;
    padding-right: 4rem;
    overflow: scroll;
    height: 92vh;
   .mid {
    gap: 2rem;
   }
}