body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chat-container {
  display: grid;
  height: 100vh;
  border-radius: 12px;
  grid-template-columns: 1fr 3fr;
  overflow: hidden;

  .sidebar {
    width: 250px;
    background-color: #e6f3ff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  
   .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
  
   .header {
    border-bottom: 1px solid #e0e0e0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-area {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
  }
  
  .input-area {
    border-top: 1px solid #e0e0e0;
    padding: 16px;
  }
  
  .button {
    background-color: #f0f0f0;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .button:hover {
    background-color: #e0e0e0;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .message {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    overflow: hidden;
    word-break: break-word;
  }
  
  .message-content {
    background-color: #f0f0f0;
    padding: 12px;
    border-radius: 16px;
    width: 80%;
    white-space: break-spaces;
    overflow: hidden;
  }
  
  .user-message {
    justify-content: flex-end;
  }
  
  .user-message .message-content {
    background-color: #007bff;
    color: white;
    width: 100%;
  }
  
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  
  .card {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
  }
  
  .icon-button:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  
  .pre-block {
    background-color: #1e1e1e;
    color: white;
    padding: 16px;
    border-radius: 8px;
    overflow-x: auto;
  }
}

