@import "./App.scss";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,800;1,900&display=swap');

@import 'rsuite/dist/rsuite.css';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-bottom: 0!important;
}
p + p {
  margin-top: 0 !important;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "Poppins", sans-serif;
}
.ht-100 {
  height: 100%;
}
.ht-100vh {
  height: 100vh;
}
.w-100 {
  width: 100%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.h-3 {
  height: 3rem;
}
.mt-0-75 {
  margin-top: 0.75rem !important;
}
.mt-0-15 {
  margin-top: 0.15rem !important;
}
.mt-0-5 {
  margin-top: 0.5rem;
}
.mt-1 {
  margin-top: 1rem !important;
}

.mt-1-5 {
  margin-top: 1.5rem;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-2-5 {
  margin-top: 2.5rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-4-5 {
  margin-top: 4.5rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.mt-7 {
  margin-top: 7rem !important;
}
.mr-0-75 {
  margin-right: 0.75rem !important;
}
.mr-0-15 {
  margin-right: 0.15rem !important;
}
.mr-0-5 {
  margin-right: 0.5rem;
}
.mr-1 {
  margin-right: 1rem !important;
}

.mr-1-5 {
  margin-right: 1.5rem;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}

.ml-0-75 {
  margin-left: 0.75rem !important;
}
.ml-0-15 {
  margin-left: 0.15rem !important;
}
.ml-0-5 {
  margin-left: 0.5rem;
}
.ml-1 {
  margin-left: 1rem !important;
}

.ml-1-5 {
  margin-left: 1.5rem;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-5 {
  margin-left: 5rem !important;
}
.ml-6 {
  margin-left: 6rem !important;
}
.ml-7 {
  margin-left: 7rem !important;
}
.ml-8 {
  margin-left: 8rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-0-2 {
  margin-bottom: 0.2rem !important;
}
.mb-0-4 {
  margin-bottom: 0.4rem !important;
}
.mb-0-5 {
  margin-bottom: .5rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-1-5 {
  margin-bottom: 1.5rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.bg-white {
  background-color: white;
}
.bg-primary {
  background-color: $primary;
}
.bg-secondary {
  background-color: $secondary;
}
.bg-medium-grey {
  background-color: #eeeeee !important;
}
.text-dark {
  color: black;
}
.text-light {
  color: #ffffff;
}

.text-captalize {
  text-transform: capitalize;
}

.no-wrap {
  text-wrap: nowrap;
}

.text-incoming {
  color: #66C6A9;
}
.text-outgoing {
  color: #F3A83B;
}
.text-missed {
  color: #ff0000;
}
.text-rejected {
  color: #a52a2a;
}

.p-0 {
  padding: 0 !important;
}
.p-0-5 {
  padding: 0.5rem !important;
}
.p-1 {
  padding: 1rem!important;
}
.p-2 {
  padding: 2rem!important;
}


.p-x-0-5 {
  padding: 0 0.5rem !important;
}
.p-x-1 {
  padding: 0 1rem;
}
.p-y-0-5 {
  padding: 0.5rem 0 !important;
}
.p-y-1 {
  padding: 1rem 0 !important;
}
.pr-0 {
  padding-right: 0rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem !important;
}
.pr-3 {
  padding-right: 3rem;
}
.pr-3-imp {
  padding-right: 3rem !important;
}
.pr-4 {
  padding-right: 4rem !important;
}
.pr-5 {
  padding-right: 5rem !important;
}

.pl-0 {
  padding-left: 0rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pl-3 {
  padding-left: 3rem;
}
.pl-3-imp {
  padding-left: 3rem !important;
}
.pl-4 {
  padding-left: 4rem !important;
}
.pl-5 {
  padding-left: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-0-5 {
  padding-top: 0.5rem !important;
}


.pt-1 {
  padding-top: 1rem !important;
}
.pt-1-5 {
  padding-top: 1.5rem !important;
}


.pt-2 {
  padding-top: 2rem !important;
}
.pt-2-5 {
  padding-top: 2.5rem !important;
}
.pt-3 {
  padding-top: 3rem !important;
}
.pt-3-5 {
  padding-top: 3.5rem !important;
}
.pt-4 {
  padding-top: 4rem !important;
}

.pt-4-5{
  padding-top: 4.5rem !important;
}
.pt-5 {
  padding-top: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-0-5 {
  padding-bottom: 0.5rem !important;
}


.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-1-5 {
  padding-bottom: 1.5rem !important;
}


.pb-2 {
  padding-bottom: 2rem !important;
}
.pb-2-5 {
  padding-bottom: 2.5rem !important;
}
.pb-3 {
  padding-bottom: 3rem !important;
}
.pb-3-5 {
  padding-bottom: 3.5rem !important;
}
.pb-4 {
  padding-bottom: 4rem !important;
}

.pb-4-5{
  padding-bottom: 4.5rem !important;
}
.pb-5 {
  padding-bottom: 5rem !important;
}



.ml-1 {
  margin-left: 1rem;
}
.d-inline-block {
  display: inline-block;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.d-flex-col {
  display: flex;
  flex-direction: column;
}
.d-flex-row {
  display: flex;
  flex-direction: row;
}

.display-none {
  display: none !important;
}
.gap-0-5 {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}

.justify-content-left {
  justify-content: left;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between !important;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-self-left {
  justify-self: flex-start;
}

.justify-self-right {
  justify-self: flex-end;
}

.justify-self-center {
  justify-self: center;
}


.align-item-start {
  align-items: flex-start;
}

.align-item-center {
  align-items: center !important;
}
.align-item-baseline {
  align-items: baseline;
}
.align-item-end {
  align-items: end;
}

.align-item-stretch {
  align-items: stretch;
}

.align-self-center {
  align-self: center;
}
.fs-v-sm {
  font-size: 0.6rem!important;
}
.fs-sm {
  font-size: 0.8rem;
}
.fs-med {
  font-size: 1rem;
}
.fs-big {
  font-size: 1.2rem;
}

.fs-bigger {
  font-size: 1.5rem;
}
.fs-2 {
  font-size: 2rem;
}
.fw-500 {
  font-weight: 500;
}
.fw-550 {
  font-weight: 550;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-450 {
  font-weight: 450;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: bold;
}

.ta-center {
  text-align: center;
}
.ta-left {
  text-align: left;
}

.text-color-default {
  color: black;
}
.text-color-light-grey {
  color: #a09e9e;
}
.text-color-grey-dark {
  color: #69747E;
}
.text-color-values {
  color: #41416e;
}
.text-color-shadow-text {
  color: #8d8e9c;
}

.text-color-positive {
  color: #7fba7a;
}
.text-color-light-grey-1 {
  color: #999fae;
}
.text-color-light-grey-2 {
  color: #6e6e6e;
}
.text-color-light-grey-3 {
  color: #8C90A1;
}

.blur-1{
  filter: blur(1px);

}
.blur-2{
  filter: blur(2px);

}
.blur-3{
  filter: blur(3px);

}


.text-color-blue {
  color: #5956E9!important;
}
.text-color-dark-blue {
  color: #41416E;
}
.text-color-black {
  color: #3D4043  ;
}

.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-auto {
  overflow: auto;
}
.elevatedCard {
  height: 100%;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
padding: 1rem;
  background-color: white;
  // overflow: hidden;
  border: none;
}

.light-blue-border-card {
  // border: 1px solid #CFD8FF;
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
  width: 15vw; 
  // margin-right: 1rem;
  flex: 1 0 auto;
}
.light-blue-border-card-fix {
  // border: 1px solid #CFD8FF;
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
  // width: 13vw!important; 
  // margin-right: 1rem;
  // flex: 1 0 auto;
  // margin: 0 auto;
}

.vertical-collpsed .light-blue-border-card {
  // border: 1px solid #CFD8FF;
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
  width: 17vw; 
  flex: 1 0 auto;
  margin: 0 auto;

}

.font-setup {
  font-family: 'Montserrat', sans-serif !important;
  letter-spacing: 0.6px;
}


.cursor-pointer {
  cursor: pointer;
}
.cursor-none {
  cursor: unset;
}

.primary-btn-blue {
  background-color: #5956E9;
  border-radius: 20px;
  padding: 5px 16px;
  color: white;
  font-size: .9rem;
  font-weight: 420;
  // height: 2rem;
  border: none;
}
.primary-disabled-btn {
  background-color: #a09e9e;
  border-radius: 20px;
  padding: 5px 16px;
  color: white;
  font-size: .9rem;
  font-weight: 420;
  height: 2rem;
  border: none;
}



// React strap style override

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

.btn-secondary.focus, .btn-secondary:focus  {
  box-shadow: none!important;
}

.btn.focus, .btn:focus  {
  box-shadow: none!important;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: inherit;
}


///Remove padding from Col Component React Strap

.col {
  padding: 0!important;
}

@media (min-width: 568px) {
input {
  // width: unset!important;
    /* width: calc((100% - 130px) / 2); */
}
}


/// Custom Scroll bar 

.default-scroll-bar {
  overflow: auto;
  &::-webkit-scrollbar{
    width: 8px;
    height: 6px;
    // background-color: white;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(236, 236, 236, 1); 
    border-radius: 10px;
    background-color: white;
}

&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 16px rgb(175, 175, 175); 
    background-color: white;
}
}

/// Default Box Shadow

.default-box-shadow {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.05);

}


/// remove default black border from all buttons

button {
  border: none;
}


/// React strap button focus color 
.btn-secondary:focus { 
  background: unset;
}

/// Remove default input focus outline
input {
  &:focus-visible{
    outline: none;
  }
}


.text-transform-capitalize {
  text-transform: capitalize;
}
.ws-nowrap {
  white-space: nowrap;

}

/// Mui checkbox default fill color change

.MuiCheckbox-colorSecondary.Mui-checked {
 color: #5956E9!important;
}
.flex-wrap-no-wrap {
  flex-wrap: nowrap;
}

.text-align-center {
  text-align: center;
}

.position-relative {
  position: relative;
}


.row {
  margin-left: 0;
  margin-right: 0;
}

.td-none {
  text-decoration: none;
}

.bg-grey {
  background: #fafafa ;
}

.tt-c {
  text-transform: capitalize;
}


.height-fit-content {
  height: fit-content;
}

.zIndex-1 {
  z-index: 1;
}

.rs-picker-menu {
  z-index: 9999 !important;
}

.rs-table-row[data-depth="1"] {
  .rs-table-cell-content {
    background-color: #F9F9F9;
    font-size: 0.9em;
  }
  .rs-table-cell-first {
    div {
      padding-left: 60px !important;
    }
  }
  .rs-table-cell {
    border: none;
  }
}

// ****start****/
/// Addded these styles from feedback.css file after adding lazy 
/// loading coz some files styling were dependent on these styles
// ****start****//
.feedback-main-block html,
.feedback-main-block body {
  min-height: 100%;
}
.feedback-main-block body,
.feedback-main-block p {
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.feedback-main-block {
  width: 46%;
  padding: 11px;
  margin-left: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feedback-main-block .radio {
  display: inline;
  margin-left: 8px;
}
.feedback-main-block form {
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 5px #ccc;
}
.feedback-main-block h1 {
  font-weight: 400;
  line-height: 28px;
}
.feedback-main-block hr {
  margin: 20px 0;
}
.feedback-main-block span.required {
  color: red;
}
.personal-details,
.question-block,
.statements-block {
  padding-bottom: 20px;
}
.personal-details > div {
  display: flex;
  flex-direction: column;
}


.feedback-main-block input {
  padding: 8px 5px;

  border-radius: 3px;
  border: 1px solid #ccc;
  outline: none;
  vertical-align: middle;
}
.feedback-main-block input:hover,
textarea:hover {
  outline: none;
}
.question,
.answer,
table,
textarea {
  width: 100%;
}
.answer input,
table input {
  width: auto;
}
.feedback-main-block  th,
.feedback-main-block td {
  width: 14%;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  text-align: center;
  vertical-align: unset;
  line-height: 18px;
  font-weight: 400;
  word-break: break-all;
}
.feedback-main-block .first-col {
  width: 30%;
  text-align: left;
}
.feedback-main-block small {
  display: block;
  line-height: 18px;
  opacity: 0.5;
}
.feedback-main-block .btn-block {
  text-align: center;
}
.feedback-main-block button {
  width: 150px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: #095484;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.feedback-main-block button:hover {
  background: #0666a3;
}
@media (min-width: 568px) {
  .personal-details > div {
    flex-direction: row;
    align-items: center;
  }
  .feedback-main-block label {
    width: 95px;
  }
  .feedback-main-block input {
    width: calc((100% - 130px) / 2);
  }
  .feedback-main-block input.first-name,
  .feedback-main-block input.seminar-title {
    margin: 0 5px 10px;
  }
  .feedback-main-block .question-block {
    display: flex;
    justify-content: space-between;
  }
  .feedback-main-block .question,
  .feedback-main-block .answer {
    width: 50%;
  }
  .feedback-main-block th,
  td {
    word-break: keep-all;
  }
}


// ****end****/
/// Addded these styles from feedback.css file after adding lazy 
/// loading coz some files styling were dependent on these styles
// ****end****//



// ****start****/
/// Rsuite styles
// ****start****//


.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(102, 126, 234) !important;
}

.rs-picker-toggle {
  border: none !important;
}

.rs-icon {
  display: none;
}

.rs-picker-toggle-textbox {
  padding-right: 10px;
}

.rs-picker-date .rs-picker-toggle.rs-btn, .rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 10px;
}

.rs-picker-toggle-textbox::placeholder {
  color: black;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: black;
}

.rs-btn-link {
  color: rgb(102, 126, 234) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: rgb(102, 126, 234) !important;
}

.rs-calendar-table-cell-content:hover {
  background-color: #f2faff !important;
}

.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start {
  left: 73.5vw !important;
  z-index: 99999999999999999;
}

.rs-btn-primary:disabled, .rs-btn-primary.rs-btn-disabled {
  background-color: rgb(102, 126, 234) !important;
}

.rs-btn-primary {
  background-color: rgb(102, 126, 234) !important;
}


// ****end****/
/// Rsuite styles
// ****end****//

.right-border-light-gray-2 {
  border-right: 2px solid #d5d2d2;
}