.groupedButtonsContainer {
  display: flex;
  background-color: #ECECFF;
  border-radius: 10px;
  button {
    width: 50%;
    margin: 5px;
    padding: 0.5em 1em;
    border-radius: 10px;
    background-color: transparent;
    color: #3D4043;
    font-size: 1.15em;
    font-weight: 500;
  }
  .activeBtn {
    background-color: #5956E9;
    color: white;
  }
}

.toggleContainer {
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
  color: #99A1AA;
  h6 {
    user-select: none;
    cursor: pointer;
  }
  .activeToggle {
    color: #5956E9;
  }
  .toggleBoundary {
    width: 4em;
    border: 1px solid #99a1aa;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    margin: 0 2em;
    .toggleDot {
      background-color: #5956E9;
      border-radius: 50%;
      height: 1.25em;
      width: 1.25em;
    }
  }
}

.toggleButtonOuter {
  width: 25px;
  background: white;
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 50px;
  margin-right: 7px;
  border: 2px solid #5956E9;
  .toggleButtonInner {
    height: 10px;
    width: 10px;
    background-color: #5956E9;
    border-radius: 50%;
  }
}

.planContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 1.5em;
  box-shadow: 2px 10px 17.3512px rgba(41, 41, 48, 0.05);
  user-select: none;
  cursor: pointer;
  position: relative;
  .discount {
    position: absolute;
    top: -2em;
    right: 0;
    height: 2em;
    border-radius: 20px;
    background-color: #D4F1EB;
    color: #55BC9F;
    padding: 0.25em 0.75em;
  }
  .info {
    p {
      color: black;
      font-weight: 400;
    }
    .type {
      font-size: 12px;
    }
    .name {
      font-size: 24px;
    }
    .price {
      font-size: 18px;
      color: #5956E9;
    }
    ul {
      margin: 2em 0;
      li {
        margin-left: 1em;
        font-size: 13px;
      }
      li:not(:last-child) {
        margin-bottom: 1em;
      }
    }
    .addonsContainer {
      margin-bottom: 2em;
      .addon {
        background: #FFFFFF33;
        padding: 0.5em 1em;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      .addon:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
  button {
    padding: 0.5em 1em;
    border-radius: 10px;
    color: white;
    background-color: #5956E9;
  }
}

.planContainer:hover, .darkPlan {
  background-color: #5956E9;
  color: white;
  .info {
    p {
      color: white;
    }
    .price {
      color: white;
      font-weight: 500;
    }
  }
  button {
    background-color: white;
    color: #5956E9;
    font-weight: 500;
  }
}

.popupContainer {
  position: absolute;
  top: 50%;
  left: 59%;
  transform: translate(-50%, -50%);
  width: 70rem;
  box-shadow: 24;
  background-color: #f4f4f4;
  border-radius: 10px;
  // height: 42rem;
  padding: 25px 35px;
  .header {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: black;
    img {
      cursor: pointer;
    }
  }
  p {
    color: #999FAE;
    font-weight: 300;
    font-size: 1.25em;
  }
  .plansContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
  }
}