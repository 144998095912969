.leadsquared-modal {
  h5 {
    text-align: center;
  }
  .select-txt {
    font-size: 12px;
  }
  .custom-input-group {
    gap: 4px;
  }
  .btns-container {
    // width: 70%;
    justify-content: space-evenly;
    margin-top: 1rem;
    width: 100%;
    > button {
      // width: rem;
      height: 2rem;
      text-align: center;
      padding: 3px 14px;
      border-radius: 10px;
      font-size: 12px;
      width: 40%;
    }
    .cancel-btn {
      background: white;
      color: #8c90a1;
      border: 1px solid #8c90a1;
    }
    .sync-btn {
      background: white;
      color: #226db4;
      border: 1px solid #226db4;
    }
  }
}
