.google-sheet-integration-page-container {
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .navigation {
    display: flex;
    align-items: center;
    width: max-content;
    margin-top: 0.5rem;
    p {
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }

    .arrow-icon {
      font-size: 9px;
      color: black;
      width: 1.5rem;
    }
    .active-link {
      color: #5956e9;
    }
  }

  .middle-section-container {
    background-color: #ebf3fd;
    border-radius: 10px;
    padding: 2rem !important;
    margin-left: 0;
    // max-width: 85vw;
    max-width: fit-content;

    .top {
      flex-direction: column;
      .header {
        color: #41416e;
        font-weight: 600;
        width: fit-content;
      }
      .choose-option {
        color: #6e6e6e;
        width: fit-content;
      }
    }

    .bottom {
      .drop-file-section-container {
        background: white;
        padding: 2rem 1rem;

        border-radius: 20px;

        height: 20rem;
        margin: 0;
        // max-width: 46%;
        // align-items: center;
        justify-content: center;
        align-items: center;

        .drop-file,
        .sc-bdfBQB {
          margin: 2rem;
          border: 2px dashed #b9b8b8;
          padding: 1rem;
          height: 10rem;
          border-radius: 10px;
          width: 100%;
          align-items: center;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          .txt-1 {
            color: #3f618b;
            font-size: 16px;

            .active-txt {
              color: #007bff;
              padding-left: 4px;
            }
          }

          .txt-2 {
            color: #b9b8b8;
            font-size: 12px;
          }
        }
        .gs-icon {
          height: 5rem;
          padding-bottom: 1rem;
        }
      }

      .sync-file-section-container {
        background: white;
        border-radius: 20px;
        padding: 4rem!important;
        height: 20rem;
        margin: 0;
        // max-width: 46%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 30rem;
        .txt {
          color: #3f618b;
          font-weight: 500;
          font-size: 1rem;
          text-align: center;
        }

        .sync-btn {
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 10px;
          margin-top: 2rem;
        }
      }

      .or-txt {
        color: black;
        font-weight: 600;
        font-size: 1rem;
        width: 2rem !important;
        flex-grow: unset;
        padding: 0;
        margin: 0 2rem;
      }
    }
  }
/// Modal container
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

// input[type="file"] {
//   display: none;
// }





.google-sheet-modal {

  .rs-dropdown {
    width: 100%;
  }
  .rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn {
    width: 100%;
  }
  .modal-container {
    .top {
      .search-files-input {
        width: 70%;
        border-radius: 10px;
      }
    }

    .select-txt {
      color: #3D4043;
      font-size: 13px;
      font-weight: 500;
    }

    .select-sheet-dd-container {
      width: 14rem;
      border: 1px solid #6e757c;
      border-radius: 10px;
      padding: 5px 10px;
      position: relative;
      height: 2rem;
      .sheet-search-container {
        height: 1.3rem;
        align-items: center;
        justify-content: space-between;
        .search-icon {
          height: 18px;
          fill: #41416E;
        }
        .search-input {
          width: 9rem!important;
          height: 1.5rem;
          font-size: 13px;
          border: none;
          &:focus-visible {
            outline: none;
          }
        }
        .arrow-down {
          height: 16px;
          fill: #41416E;
        }
      }
      .selected-sheet-container {
        font-size: 12px;
        align-items: center;
        justify-content: space-between;
        .selected-sheet {
          width: 10.5rem;
          white-space: nowrap;
          overflow: hidden;
        }
        .arrow-down {
          height: 1rem;
        }
      }
      .dd-items {
        position: absolute;
        top: 2.5rem;
        background-color: white;
        width: 14rem;
       max-height: 6rem;      
    z-index: 5;
    left: 0;
    font-size: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        .dd-item {
          padding: 3px 5px;
          white-space: nowrap;
          // width: 12rem;
          &:hover {
            background: #007bff;
          }
        }
      }
    }

    .select-subsheet-dd-container {
      width: 14rem;
      border: 1px solid #6e757c;
      border-radius: 10px;
      padding: 5px 10px;
      position: relative;
      height: 2rem;
      .selected-subsheet-container {
        font-size: 12px;
        align-items: center;
        justify-content: space-between;
        .selected-subsheet {
          width: 10.5rem;
          white-space: nowrap;
          overflow: hidden;
        }
        .arrow-down {
          height: 1rem;
        }
      }
      .dd-items {
        position: absolute;
        top: 2.5rem;
        background-color: white;
        width: 14rem;
       max-height: 6rem;      
    z-index: 5;
    left: 0;
    font-size: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    

        .dd-item {
          padding: 3px 5px;
          white-space: nowrap;
          // width: 12rem;
          &:hover {
            background: #007bff;
          }
        }
      }
    }

    .files-container {
      // overflow: auto;
      // height: 10rem;
      // width: 90%;
      
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

      .file-container {
        align-items: center;

        .checkbox-round {
        }

        .file-info-container {
          .name {
            color: black;
            font-weight: 450;
          }
          .id {
            color: #6e6e6e;
            font-size: 12px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
        // background-color: white;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 10px rgba(236, 236, 236, 1);
        border-radius: 10px;
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 16px gray;
        background-color: white;
      }
    }

    .btns-container {
      // width: 70%;
      justify-content: space-evenly;
      margin-top: 1rem;
      > button {
        // width: rem;
        height: 2rem;
        text-align: center;
        padding: 3px 14px;
        border-radius: 10px;
        font-size: 12px;
      }
      .cancel-btn {
        color: black;
        background: white;
      }
      .sync-btn {
        color: white;
        background: #5956e9;
      }
      
    }
  }
}
