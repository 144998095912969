@import './assets/scss/_variables.scss';
@import './assets/scss/components/_buttons.scss';
@import './assets/scss/components/_avatar.scss';
@import './assets/scss/components/_topbar.scss';
@import './assets/scss/components/_vertical.scss';
@import './assets/scss/components/_custom-scrollbar.scss';


/// Toasts style override

.Toastify__toast {
    padding-top: 0!important;
    padding-bottom: 0!important;

    background: #323232!important;
    color: white!important;
    .Toastify__toast-icon {
        display: none;
    }

    .Toastify__progress-bar--success{
        background: #66C6A9!important;
    }
    
    .Toastify__toast-body {
        padding-top: 10px;
        color: white;
    }

    /// to place the progress bar on top instead of bottom
    .Toastify__progress-bar  {
        top: 0;
        width: 110%;
    }
    /// hide close btn 
    .Toastify__close-button{
        display: none;
    }

}