.webhook-overview-section {
  flex-basis: 50%;
  h5 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: black;
  }
  .features-con {
    border-radius: 10px;
    border: 1px solid var(--primary-100, #E4DAFF);
    padding: 1rem 2rem;
    gap: 1rem;
    .overview {
      font-size: 14px;
      color: black;
      font-weight: 500;
    }
    .example {
      color: #737680;
      font-size: 14px;
      font-weight: 500;
    }

    .features-items {
      gap: 1rem;
      .feature-item {
        margin-bottom: 1rem;
        gap: 1rem;
    align-items: center;

        .icons-con {
          gap: 1rem;
          img {
            height: 2rem;
          }
        }
        .feature {
            
    font-size: 14px;
    color: black;
    font-weight: 500;

        }
      }
    }
  }
}
