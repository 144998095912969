.integration-card-container {
  // width: 18rem;
//   height: 10rem;
  padding: 1rem;
  // box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  .connected {
    align-self: flex-end;
    align-items: center;
    background: #eceff1;
    padding: 3px 8px;
    border-radius: 20px;

    p {
      color: #32a84e;
      font-size: 12px;
      line-height: 12px;
      padding: 3px 8px;
      border-radius: 20px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .not-connected {
    align-self: flex-end;
    align-items: center;
    background: #cfd8dc;
    padding: 3px 8px;
    border-radius: 20px;

    p {
      color: #607d8b;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  .header {
    margin-top: 0 !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    .logo-container {
      align-items: center;
      justify-content: center;
      height: 3rem;
      // background: #CDE2FD;
      width: 3rem;
      border-radius: 25px;

      .logo {
        height: 1.5rem;
      }
    }
    .title-container {
      margin-left: 1rem;
      .title {
        font-weight: 500;
        margin-bottom: 0;
        color: #41416e;
        font-weight: 600;
      }
      .type {
        font-weight: 500;
        margin-bottom: 0;
        color: #41416e;
        font-weight: 600;
      }
    }
  }

  .details {
    font-size: 12px;
    color: #292941;
    font-weight: 500;
  }

  .configure-btn {
    
    background-color: #304ffe;
    width: fit-content;
    padding: 2px 12px;
    border-radius: 5px;
    align-items: center;

    p{
        font-size: 12px;
        margin-left: 1rem;
        color: white;
        font-weight: 600;
    }

  }
}
