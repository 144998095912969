.vertical-menu {
    background-color: white;
    z-index: 9999;
    transition: width 0.3s ease-in-out; 
    position: absolute;

    &.expanded {
        width: 250px; // Expanded width
    }

    .navbar-brand-box {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        background-color: white;
        border-radius: 0;
        padding: 1.5rem 1rem 0.5rem 18px;
        width: 100% !important;

        .orgInfo {
            display: flex;
            align-items: center;
        }

        .org-name {
            color: #5956E9;
            font-size: 1.35rem;
            font-weight: 600;
            margin-left: 0.75em;
            white-space: nowrap; // Prevent text from wrapping
            overflow: hidden; // Hide overflow when collapsed
            opacity: 1; // Hide organization name when collapsed
            transition: opacity 0.3s ease-in-out; // Smooth transition for text visibility
        }
    }

    &.expanded .navbar-brand-box .org-name {
        opacity: 1; // Show organization name when expanded
    }
}
